var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('data-table',{ref:"table",attrs:{"client":_vm.client,"client-function":_vm.clientFunction,"client-function-parameters":_vm.clientFunctionParameters,"headers":_vm.headers,"actions":_vm.actions},scopedSlots:_vm._u([{key:"item.day_of_week",fn:function(ref){
var item = ref.item;
return [_vm._v(" "+_vm._s(_vm.formatDayOfWeek(item.day_of_week))+" ")]}},{key:"item.place",fn:function(ref){
var item = ref.item;
return [_vm._v(" "+_vm._s(item.place.name)+" ")]}},{key:"item.start_times",fn:function(ref){
var item = ref.item;
return [_vm._v(" "+_vm._s(_vm._f("formatStartTimes")(item.start_times))+" ")]}},{key:"item.active",fn:function(ref){
var item = ref.item;
return [(item.active)?_c('v-chip',{attrs:{"color":"secondary","small":""}},[_vm._v(" "+_vm._s(_vm.$t("timetables.active"))+" ")]):_vm._e()]}}])})}
var staticRenderFns = []

export { render, staticRenderFns }